
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import web3Modal from '@/wallet'

import NavMenu from '@/components/NavMenu.vue'
import { formatMixin } from '@/format'
import { delay } from '@/utils'
import { IEcosystem } from '@/ecosystem'

type NavItem = {
  name: string,
  desc: string,
  href: string,
  iconSrc: string,
  target: string
}

type NavSection = {
  title: string,
  items: NavItem[],
  autoExpand?: boolean
}

const DESKTOP_SCROLL_THRESHOLD = 430

export default Vue.extend({
  name: 'App',
  mixins: [formatMixin],
  components: { NavMenu },
  data() {
    return {
      isNavigationDrawerOpen: false,
      windowScroll: 0,
      DESKTOP_SCROLL_THRESHOLD
    }
  },
  computed: {
    ecosystem(): IEcosystem {
      return this.$store.getters.ecosystem
    },
    navBarElevation() {
      if (this.isNavigationDrawerOpen) {
        return 24
      }

      // elevate when scrolling past header box, but not on mobile
      if (this.windowScroll > DESKTOP_SCROLL_THRESHOLD &&
          this.$vuetify.breakpoint.mdAndUp) {
        return 24
      }

      return 0
    },
    navSections(): NavSection[] {
      // TODO: refactor to IEcosystem
      return [{
        title: 'Exchange',
        autoExpand: true,
        items: [{
          name: 'Swap',
          desc: 'Buy or sell any token instantly',
          href: '/swap',
          target: '_self',
          iconSrc: require('@/assets/icons/Swap Icon.svg')
        },
        {
          name: 'Farms',
          desc: 'Earn passive income by staking liquidity',
          href: '/farms',
          target: '_self',
          iconSrc: require('@/assets/icons/Farms Icon.svg')
        },
        {
          name: 'Liquidity',
          desc: 'Add liqudity, earn transaction fees',
          href: '/liquidity',
          target: '_self',
          iconSrc: require('@/assets/icons/Faucet Icon.svg')
        },
        {
          name: 'Bridge',
          desc: 'Bridge TOAD between chains',
          href: '/bridge',
          target: '_self',
          iconSrc: require('@/assets/icons/Bridge Icon.svg')
        },
        {
          name: 'Vault',
          desc: 'PAD\'s backing reserves',
          href: '/vault',
          target: '_self',
          iconSrc: require('@/assets/icons/Vault Icon.svg')
        }]
      },
      {
        title: 'Create',
        autoExpand: true,
        items: [
        {
          name: 'Token factory',
          desc: 'Create your own token in a few clicks',
          href: '/tokenfactory',
          target: '_self',
          iconSrc: require('@/assets/icons/Token List Icon.svg')
        },
        {
          name: 'Launchpad',
          desc: 'Partake in presales, or start your own',
          href: '/launchpad',
          target: '_self',
          iconSrc: require('@/assets/icons/LaunchPAD Icon.svg')
        }]
      },
      {
        title: 'Socials',
        items: [
        {
          name: 'YouTube',
          desc: 'Watch our series of educational animated videos',
          href: 'https://www.youtube.com/channel/UCI_vUc-HrJWtKXj-Re-hTSw',
          target: '_blank',
          iconSrc: require('@/assets/icons/YouTube Icon.svg')
        },
        {
          name: 'Telegram',
          desc: 'Come chat with us on Telegram',
          href: 'https://t.me/toadnetwork',
          target: '_blank',
          iconSrc: require('@/assets/icons/Telegram Icon.svg')
        },
        {
          name: 'Discord',
          desc: 'Our own Discord server',
          href: 'https://discord.gg/yChaaxyGjf',
          target: '_blank',
          iconSrc: require('@/assets/icons/Discord Icon.svg')
        },
        {
          name: 'Reddit',
          desc: 'Partake in discussions about Toad.Network on our subreddit',
          href: 'https://reddit.com/r/toadnetwork',
          target: '_blank',
          iconSrc: require('@/assets/icons/Reddit Icon.svg')
        },
        {
          name: 'Twitter',
          desc: 'Stay up to date with our latest news',
          href: 'https://twitter.com/toadnetwork',
          target: '_blank',
          iconSrc: require('@/assets/icons/Twitter Icon.svg')
        }]
      },
      {
        title: 'Info',
        items: [{
          name: 'Docs',
          desc: 'Learn all about us on our Wiki',
          href: 'https://docs.toad.network/',
          target: '_blank',
          iconSrc: require('@/assets/icons/Docs Icon.svg')
        },
        {
          name: 'PadSwap Stats',
          desc: 'Analyze token prices and their volume',
          href: this.ecosystem.infoUrl,
          target: '_blank',
          iconSrc: require('@/assets/icons/Stats Icon.svg')
        },
        {
          name: 'Donate',
          desc: 'Help us fund TOAD\'s marketing',
          href: '/donate',
          target: '_self',
          iconSrc: require('@/assets/icons/Vault Icon.svg')
        },
        ]
      }]
    },
    ...mapGetters(['isConnected']),
    ...mapState(['padPrice']),
  },
  created() {
    window.addEventListener('scroll', this.updateScroll)
  },
  async mounted() {
    this.startSyncing()

    await delay(0)
    if (web3Modal.cachedProvider) {
      await this.connectWallet()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    async connectWallet() {
      if (this.isConnected) {
        return
      }

      this.$store.dispatch('requestConnect')
    },
    async startSyncing() {
      while (true) {
        try {
          this.sync()
        } catch (e) {
          console.error(e)
        }

        await delay(5000)
      }
    },
    async sync() {
      const ecosystem = this.ecosystem
      const blockNumber = await ecosystem.dataseed.getBlockNumber()
      await ecosystem.priceModel.syncWithin(blockNumber, 20)

      if (this.ecosystem.ecosystemId == ecosystem.ecosystemId) {
        const padPrice = ecosystem.priceModel.getPriceUsd(ecosystem.padAddress)
        // TODO: remove setPadPrice calls from landing/farms and update only here
        this.$store.commit('setPadPrice', padPrice)
      }
    },
    updateScroll() {
      this.windowScroll = window.scrollY
    }
  },
  filters: {
    formatAddress(val: string) {
      return val.substring(0, 3) + '...' + val.substring(val.length - 3)
    }
  }
})
