
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { ethers } from 'ethers'

import {
  ERC20_ABI,
  FARM_REQUIRED_ALLOWANCE,
  PADSWAP_FARM_ABI,
  PADSWAP_SINGLE_STAKE_FARM_ABI,
  PADSWAP_LP_FARM_ABI,
  APPROVE_AMOUNT } from '@/constants'
import { IEcosystem, EcosystemId, ECOSYSTEMS } from '@/ecosystem'
import { formatMixin } from '@/format'
import { FarmData } from '@/types'
import { delay, equalsInsensitive, toFloat } from '@/utils'

type ValidationStatus = {
  status: boolean,
  message?: string
}

const IMAGE_OVERRIDES = {
  CZATS: require('@/assets/tokens/bsc/CZATS.png'),
  DAI: require('@/assets/tokens/moonriver/DAI.png'),
  DOT: require('@/assets/tokens/bsc/DOT.png'),
  FUK: require('@/assets/tokens/bsc/FUK.png'),
  KISH: require('@/assets/tokens/bsc/KISH.png'),
  MOVR: require('@/assets/tokens/moonriver/MOVR.png'),
  MRBTC: require('@/assets/tokens/bsc/MRBTC.png'),
  RDOGE: require('@/assets/tokens/moonriver/RDOGE.png'),
  GLMR: require('@/assets/tokens/moonbeam/GLMR.png'),
  WGLMR: require('@/assets/tokens/moonbeam/WGLMR.png')
}

const TOAD = '0x463e737d8f740395abf44f7aac2d9531d8d539e9'
const WGLMR = '0xe3DB50049C74De2F7d7269823af3178Cf22fd5E3'
const WBNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
// TODO: use 0x98878b06940ae243284ca214f92bb71a2b032b8a?
const WMOVR = '0x663a07a2648296f1A3C02EE86A126fE1407888E5'

export default Vue.extend({
  name: 'Farm',
  mixins: [formatMixin],
  props: {
    name: String,
    contract: String,
    acceptedToken: String,
    token1Address: String,
    token2Address: String,
    rewardToken: String,
    type: Number,
    isImported: Boolean,
    tokenLogoUrls: Object,
    ecosystemId: Number,
    roi: Number,
    apy: Number,
    poolSize: Number,
    poolValue: Number,
    tvl: Number,
    lpPrice: Number,
    rewardTokenPrice: Number,
    userLpBalance: ethers.BigNumber,
    userStakedBalance: ethers.BigNumber,
    userRewardsBalance: Number,
    userAllowance: Number
  },
  data() {
    const [token0, token1] = this.name.split('-')
    return {
      expand: false,
      isDetailsVisible: false,
      currentAnimations: {
        'dwButton': false,
        'enableButton': false
      },
      dwAction: <'deposit' | 'withdraw' | 'reinvest'> 'deposit',
      dwActionAmount: <string | null> null,
      token0,
      token1,
      showRemoveImportDialog: false,
      TOAD,
      WGLMR
    }
  },
  computed: {
    ecosystem(): IEcosystem {
      return ECOSYSTEMS[this.ecosystemId as EcosystemId]
    },
    userLpBalanceNum(): number {
      return parseFloat(ethers.utils.formatEther(this.userLpBalance ?? 0))
    },
    userStakedBalanceNum(): number {
      return parseFloat(ethers.utils.formatEther(this.userStakedBalance ?? 0))
    },
    isLoading(): boolean {
      return this.roi === undefined
    },
    isEnabled(): boolean {
      return this.userAllowance !== undefined && this.userAllowance > 0
    },
    isEnoughApproved(): boolean {
      if (this.userAllowance == undefined || !this.dwActionAmountBn) {
        return false
      }
      const actionAmount = parseFloat(ethers.utils.formatUnits(this.dwActionAmountBn, "0"))

      return this.userAllowance >= actionAmount
    },
    stakedLpValue(): number {
      if (!this.userStakedBalanceNum) {
        return 0
      }

      return this.userStakedBalanceNum * (this.lpPrice as number)
    },
    displayedRewardToken(): string {
      if (this.rewardToken) {
        return this.rewardToken
      } else if (this.type == 1) { // LP farm
        return this.name
      } else {
        return 'PAD'
      }
    },
    tokenImages(): string[] {
      const token0 = this.token0 as keyof typeof IMAGE_OVERRIDES
      const token1 = (this.token1 ?? this.token0) as keyof typeof IMAGE_OVERRIDES
      if (this.isImported) {
        const tokenLogoUrls = this.tokenLogoUrls ?? {}
        return [
          tokenLogoUrls[this.token1Address] ?? IMAGE_OVERRIDES[token0] ?? this.requireOrDefault(`@/assets/tokens/${this.ecosystem.tokenIconsFolder}/${token0}.svg`),
          tokenLogoUrls[this.token2Address] ?? IMAGE_OVERRIDES[token1] ?? this.requireOrDefault(`@/assets/tokens/${this.ecosystem.tokenIconsFolder}/${token1}.svg`)
        ]
      }
      return [
        IMAGE_OVERRIDES[token0] ?? require(`@/assets/tokens/${this.ecosystem.tokenIconsFolder}/${token0}.svg`),
        IMAGE_OVERRIDES[token1] ?? require(`@/assets/tokens/${this.ecosystem.tokenIconsFolder}/${token1}.svg`)
      ]
    },
    padswapLiquidityUrl(): string {
      const token1Address = equalsInsensitive(this.token1Address, this.ecosystem.wethAddress) ? this.ecosystem.ethName : this.token1Address
      const token2Address = equalsInsensitive(this.token2Address, this.ecosystem.wethAddress) ? this.ecosystem.ethName : this.token2Address
      return `/${this.ecosystem.routeName}/swap?action=add&token1=${token1Address}&token2=${token2Address}`
    },
    padswapBuyTokenUrl(): string {
      return `/${this.ecosystem.routeName}/swap?inputCurrency=GLMR&outputCurrency=${this.token1Address}`
    },
    earnedValue(): number {
      if (!this.userRewardsBalance) {
        return 0
      }

      return (this.userRewardsBalance as number) * (this.rewardTokenPrice as number)
    },
    dwActionAmountBn: {
      // TODO: token-specific decimals if ever needed
      get(): ethers.BigNumber {
        return ethers.utils.parseEther(this.dwActionAmount || '0')
      },
      set(val: ethers.BigNumber) {
        this.dwActionAmount = ethers.utils.formatEther(val)
      }
    },
    validationStatus(): ValidationStatus {
      if (this.dwActionAmount == null || this.dwActionAmountBn.lte(0)) {
        return { status: false }
      }

      if (this.dwAction == 'deposit' && !this.isEnoughApproved) {
        return { status: false, message: 'Please enable the farm first'}
      }

      if (this.dwAction == 'deposit' && this.dwActionAmountBn.gt(this.userLpBalance)) {
        return { status: false, message: 'Insufficient balance'}
      }

      if (this.dwAction == 'withdraw' && this.dwActionAmountBn.gt(this.userStakedBalance)) {
        return { status: false, message: 'Insufficient balance'}
      }

      return { status: true }
    },
    farmDataMinWidths(): string[] {
      if (this.$vuetify.breakpoint.xl) {
        return ['200px', '200px', '200px']
      } else if (this.$vuetify.breakpoint.lg) {
        return ['130px', '130px', '200px']
      } else if (this.$vuetify.breakpoint.md) {
        return ['100px', '100px', '200px']
      } else {
        return ['initial', 'initial', 'initial']
      }
    },
    farmContract(): ethers.Contract {
      if (this.type == 1) {
        return new ethers.Contract(this.contract, PADSWAP_LP_FARM_ABI, this.web3)
      } else if (this.token1Address == this.token2Address && this.token1Address != TOAD && this.token1Address != WGLMR) {
        return new ethers.Contract(this.contract, PADSWAP_SINGLE_STAKE_FARM_ABI, this.web3)
      } else {
        return new ethers.Contract(this.contract, PADSWAP_FARM_ABI, this.web3)
      }
    },
    tokenContract(): ethers.Contract {
      return new ethers.Contract(this.acceptedToken, ERC20_ABI, this.web3)
    },
    ...mapState(['web3'])
  },
  methods: {
    setMax() {
      if (this.dwAction == 'deposit') {
        this.dwActionAmountBn = this.userLpBalance
      } else {
        this.dwActionAmountBn = this.userStakedBalance
      }
    },
    async enable() {
      const tx = await this.tokenContract.populateTransaction.approve(this.farmContract.address, APPROVE_AMOUNT)
      await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId})
    },
    async harvest() {
      let tx
      if (this.type == 1) {
        tx = await this.farmContract.populateTransaction.withdraw()
      } else {
        tx = await this.farmContract.populateTransaction.harvest()
      }

      await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId})
    },
    async deposit() {
      if (!this.validationStatus.status) {
        this.shakeButton('dwButton')
        return
      }

      const tx = await this.farmContract.populateTransaction.deposit(this.dwActionAmountBn)
      await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId })
    },
    async withdraw() {
      if (!this.validationStatus.status) {
        this.shakeButton('dwButton')
        return
      }

      const tx = await this.farmContract.populateTransaction.remove(this.dwActionAmountBn)
      await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId })
    },
    async reinvest() {
      const tx = await this.farmContract.populateTransaction.reinvest()
      await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId })
    },
    async shakeButton(name: 'dwButton' | 'enableButton') {
      if (this.currentAnimations[name]) {
        return
      }
      this.currentAnimations[name] = true
      await delay(290)
      this.currentAnimations[name] = false
    },
    removeImport() {
      this.$store.state.userProfile.importedFarms[this.ecosystemId] =
        this.$store.state.userProfile.importedFarms[this.ecosystemId].filter((f: FarmData) => !equalsInsensitive(f.contract, this.contract))
      this.showRemoveImportDialog = false
    },
    requireOrDefault(path: string): string {
      try {
        return require(path)
      } catch (e) {
        console.warn(e)
        const defaultTokenName = this.ecosystem.ethName
        // TODO: use an unknown token icon
        if (defaultTokenName in IMAGE_OVERRIDES) {
          return IMAGE_OVERRIDES[defaultTokenName as keyof typeof IMAGE_OVERRIDES]
        }
        return require(`@/assets/tokens/${this.ecosystem.tokenIconsFolder}/${defaultTokenName}.svg`)
      }
    },
    ...mapActions(['safeSendTransaction'])
  }
})
