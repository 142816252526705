
  import Vue from 'vue'
  import { ethers } from 'ethers'
  import { providers } from '@0xsequence/multicall'
  import { delay, toFloat } from '@/utils'
  import { IEcosystem, EcosystemId, ChainId } from '@/ecosystem'
  import {
    PADSWAP_PAIR_ABI,
    ERC20_ABI,
  } from '../constants'

  export default Vue.extend({
    data () {
      return {
        active: <boolean> true,
        toadMarketCap: <number> 0,
        padMarketCap: <number> 0,
      }
    },
    created () {
      // Setting ecosystem to bsc
      this.$store.commit('setEcosystemId', 2)
      setTimeout(async () => {
        setTimeout(async () => {
          await this.getData()
        }, 1000)
      }, 1000)
    },
    computed: {
      ecosystemId: {
        get(): EcosystemId {
          return this.$store.state.ecosystemId
        },
        set(val: EcosystemId) {
          this.$store.commit('setEcosystemId', val)
          setTimeout(async () => {
              setTimeout(async () => {
              await this.getData()
            }, 1000)
          }, 1000)
        }
      },
      address(): string {
        return this.$store.state.address
      },
      multicall(): ethers.providers.Provider {
        return this.$store.getters.multicall
      },
      ecosystem(): IEcosystem {
        return this.$store.getters.ecosystem
      },
      priceModel() : any {
        return this. $store.getters.ecosystem.priceModel
      },
      web3(): ethers.Signer | null {
        return this.$store.state.web3
      },
      chainId(): ChainId {
        return this.$store.getters.ecosystem.chainId
      },
      chainName(): string {
        return this.$store.getters.ecosystem.routeName
      },
    },
    beforeRouteLeave (to, from, next) {
      next()
    },
    methods: {
      round(num : any, dec : any) {
        num = Number(num).toFixed(20)
        if(!Number.isFinite(Number(num))) num = '0.0'
        num = Number(num).toFixed(20)
        const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dec}})?`)
        let [int, decimals] = num.toString().replace(',', '.').split('.')
        if(dec == 0) return int
        const rounded = num.toString().match(regex)[0]
        return rounded
      },
      biOrMiOrK(num : number) : string {
        if(num>=1e9) return this.round(num/1e9, 2) + 'BI'
        else if(num>=1e6) return this.round(num/1e6, 2) + 'M'
        else if (num>=1e3) return this.round(num/1e3, 2) + 'K'
        else if (num>= 1e2) return this.round(num, 2)
        else if (num >= 1) return this.round(num, 4)
        else return this.round(num, 6)
      },
      async getData() {
        const moonbeamToadAddress = "0xf480f38c366daac4305dc484b2ad7a496ff00cea"
        const moonbeamPadAddress = "0x59193512877e2ec3bb27c178a8888cfac62fb32d"

        const toadPrice = this.priceModel.getPriceUsd(moonbeamToadAddress)
        const padPrice = this.priceModel.getPriceUsd(moonbeamPadAddress)

        const padContract = new ethers.Contract(moonbeamPadAddress, ERC20_ABI, this.multicall)
        const padSupply = await padContract.totalSupply()

        this.toadMarketCap = toadPrice * 195000
        this.padMarketCap = padPrice * toFloat(padSupply)
      },
    },
  })
