import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"padswap-farms-home"},[_c('div',{staticClass:"header-box"},[_c('div',{staticClass:"header-img-container"},[_c(VImg,{staticStyle:{"max-width":"70vw"},attrs:{"src":_vm.$padswapTheme.theme.headerLogoSrc,"contain":"","height":"150px","width":"500px"}})],1),_c('div',{staticStyle:{"font-size":"14px","color":"#B3B8C1","margin-top":"10px"}},[_vm._v("Truly decentralized, multi-chain DeFi ecosystem")])]),_c(VRow,{staticClass:"justify-center title-card-container"},[_c(VCard,{staticClass:"title-card hover-rgb",attrs:{"href":"/swap"}},[_c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("@/assets/icons/Swap Icon.svg")}})]),_c('div',{staticClass:"title-card-name"},[_vm._v("Swap")]),_c(VCardSubtitle,{staticClass:"text-center",staticStyle:{"font-size":"1.05rem"}},[_vm._v("Exchange tokens"),_c('br'),_vm._v("on multiple chains.")])],1),_c(VCard,{staticClass:"title-card hover-rgb",attrs:{"href":"/farms"}},[_c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("@/assets/icons/Farms Icon.svg")}})]),_c('div',{staticClass:"title-card-name"},[_vm._v("Earn")]),_c(VCardSubtitle,{staticClass:"text-center",staticStyle:{"font-size":"1.05rem"}},[_vm._v(" Earn passive income"),_c('br'),_vm._v(" by providing liquidity"),_c('br'),_vm._v(" and staking it in farms. ")])],1),_c(VCard,{staticClass:"title-card hover-rgb",attrs:{"href":"/vault"}},[_c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("@/assets/icons/Vault Icon.svg")}})]),_c('div',{staticClass:"title-card-name"},[_vm._v("Vault")]),_c(VCardSubtitle,{staticClass:"text-center",staticStyle:{"font-size":"1.05rem"}},[_vm._v("Collects backing for PAD from service fees")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }